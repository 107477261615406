import bugsTeam from "../../../assets/images/bug-icons/bugs-team.svg";
import { ContainedButton } from "./buttons/ContainedButton";
import cards from "../../../assets/images/credit-cards.svg";
import styled from "@emotion/styled";
import { OutlinedButton } from "./buttons/OutlinedButton";
import { useLocation } from "react-router-dom";

interface PageIntroProps {
  title: string;
  subtitle: string;
  description: string;
  showContainedButton?: boolean;
  showOutlinedButton?: boolean;
}

export const PageIntro = (props: PageIntroProps) => {
  const {
    title,
    subtitle,
    description,
    showContainedButton,
    showOutlinedButton,
  } = props;

  const location = useLocation();
  console.log(location.pathname);

  return (
    <Container isFullHeight={location.pathname === "/reviews"}>
      <ImgContainer
        src={bugsTeam}
        alt="Bugs team icon"
        width="1428"
        height="740"
      />
      {/* <Title className="animate__animated animate__zoomInDown">{title}</Title> */}
      <Subtitle>{subtitle}</Subtitle>
      <Description>
        <h5>{description}</h5>
      </Description>
      {showContainedButton ? (
        <StyledCustomButton>
          <ContainedButton
            text="Buy QASkills"
            variant="contained"
            disabled={false}
            icon={cards}
            fromPage="team page."
          />
        </StyledCustomButton>
      ) : (
        ""
      )}

      {showOutlinedButton ? (
        <StyledCustomButton>
          <OutlinedButton
            text="Write a review"
            variant="outlined"
            disabled={false}
            icon={cards}
            redirectLink="/feedback"
            color="#000000"
          />
        </StyledCustomButton>
      ) : (
        ""
      )}
    </Container>
  );
};

const Container = styled.div<{ isFullHeight: boolean }>`
  display: block;
  height: ${(props) => (props.isFullHeight ? "calc(100vh - 20rem)" : "auto")};

  @media (max-width: 1400px) {
    height: auto;
  }
`;

const ImgContainer = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: auto;
`;

const Title = styled.h2`
  text-align: center;
  padding-bottom: 5rem;
  padding-top: 3rem;
  font-weight: bold;
`;

const Subtitle = styled.h1`
  padding-top: 8rem;
  text-align: center;
  font-weight: bold;
  padding-bottom: 1rem;
`;

const Description = styled.h4`
  color: #747474;
  justify-content: center;
  margin: auto;
  vertical-align: middle;
  text-align: center;
  width: 65%;

  h5 {
    line-height: 2rem;
  }

  @media (max-width: 820px) {
    width: 85%;
  }
`;

const StyledCustomButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 5rem;
`;
