import styled from "@emotion/styled";
import { PageIntro } from "../../shared/components/PageIntro";
import { VerticalLine } from "../../shared/components/VerticalLine";
import { ContactUsForm } from "./ContactUsForm";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { LoginButton } from "../../shared/components/buttons/LoginButton";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { checkIfGuestExists } from "../../utils/guestCheck";

export const ContactUs = () => {
  const location = useLocation();

  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  console.log("isLoggedIn-", isLoggedIn);
  const [isShowLogin, setIsShowLogin] = useState<boolean>(!isLoggedIn);

  const handleClose = () => {
    setIsShowLogin?.(false);
  };

  return (
    <Container>
      <PageIntro
        description="Interested in learning more about QASkills? Feel free to fill out the form below to get in direct contact with one of our founders. "
        subtitle="Contact Us"
        title=""
      />
      <br />
      <VerticalLine />

      {isLoggedIn && !checkIfGuestExists() ? (
        <ContactUsForm buttonText="Send Message" url="/contactus" />
      ) : (
        <SignInWrapper>
          <Typography variant="body2" align="center">
            To contact us, please sign in to your account.
          </Typography>
          <ButtonWrapper>
            <LoginButton
              shouldClosePopup={handleClose}
              navigatedFromLocation={location}
            />
          </ButtonWrapper>
        </SignInWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 10rem;
  padding-right: 10rem;

  @media (max-width: 820px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media (max-width: 1368px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const ButtonWrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;

const SignInWrapper = styled.div`
  width: 90%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 5rem;
  padding: 2rem;
  background: #ffffff;

  @media (max-width: 1159px) {
    padding: 0.5rem 0.5rem 1.5rem 0.5rem;
  }

  @media (max-width: 800px) {
    margin: 0.5;
  }
`;
