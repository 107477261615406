import styled from "@emotion/styled";
import { PageIntro } from "../../shared/components/PageIntro";
import { VerticalLine } from "../../shared/components/VerticalLine";
import { FeedbackForm } from "./FeedbackForm";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Typography } from "@mui/material";
import { LoginButton } from "../../shared/components/buttons/LoginButton";
import { checkIfGuestExists } from "../../utils/guestCheck";
import { useLocation } from "react-router-dom";

export const Feedback = () => {
  const location = useLocation();

  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const [isShowLogin, setIsShowLogin] = useState<boolean>(!isLoggedIn);

  const handleClose = () => {
    setIsShowLogin?.(false);
  };

  return (
    <Container>
      <PageIntro
        description="Have something to say about QASkills? Great! We love to hear feedback from people. Feel free to share feedback about your experience with us below."
        subtitle="Feedback"
        title="Feedback"
      />
      <br />
      <VerticalLine />
      {isLoggedIn && !checkIfGuestExists() ? (
        <FeedbackForm buttonText="Send Message" url="/feedback" />
      ) : (
        <SignInWrapper>
          <Typography variant="body2" align="center">
            We appreciate your feedback. Please sign in to share it with us.
          </Typography>
          <ButtonWrapper>
            <LoginButton
              shouldClosePopup={handleClose}
              navigatedFromLocation={location}
            />
          </ButtonWrapper>
        </SignInWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 10rem;
  padding-right: 10rem;

  @media (max-width: 820px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const ButtonWrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;

const SignInWrapper = styled.div`
  width: 90%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 5rem;
  padding: 2rem;
  background: #ffffff;

  @media (max-width: 1159px) {
    padding: 0.5rem 0.5rem 1.5rem 0.5rem;
  }

  @media (max-width: 800px) {
    margin: 0.5;
  }
`;
