import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import styled from "@emotion/styled";

import { Button, useMediaQuery, useTheme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { setLightTheme, setDarkTheme } from "../../../../actions";
import LogoIcon from "../../../../assets/images/Logo.svg";
import { LoginPopup } from "../../../login/LoginPopup";
import { UserMenu } from "./user-menu/UserMenu";
import { ContentMenu } from "./content-menu/ContentMenu";
import { Drawer } from "./drawer/Drawer";
import { Mixpanel } from "../../../../../Mixpanel";

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isShowLogin, setIsShowLogin] = useState(false);

  const currentTheme = useSelector((state: any) => state.currentTheme);
  const dispatch = useDispatch();

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme !== currentTheme && theme === "light") {
      dispatch(setLightTheme());
    }
    if (theme !== currentTheme && theme === "dark") {
      dispatch(setDarkTheme());
    }
  }, []);

  const isSmallerDevice = useMediaQuery("(min-width:1200px)");

  const toggleTheme = () => {
    if (currentTheme === "darkTheme") {
      dispatch(setLightTheme());
      localStorage.setItem("theme", "light");
    } else if (currentTheme === "lightTheme") {
      dispatch(setDarkTheme());
      localStorage.setItem("theme", "dark");
    }
  };

  const theme = useTheme();

  const id = isOpen ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(false);
    setIsOpen(false);
  };

  return (
    <StyledHeader {...{ theme }}>
      <LogoContainer to="/" {...{ theme }}>
        <img src={LogoIcon} alt="Logo icon" width="120" height="40" />
        {/* Wait for next new year :D <Image src={santaIcon}/> Happy new year 2021 */}
      </LogoContainer>
      {isSmallerDevice ? (
        <LinksContainer>
          <StyledNavLink
            to="/qaskills"
            {...{ theme }}
            onClick={() => Mixpanel.track("Header navigation homepage.")}
          >
            QASkills
          </StyledNavLink>
          <StyledNavLink
            to="/automation-infra"
            {...{ theme }}
            onClick={() => Mixpanel.track("Header navigation automationInfra.")}
          >
            AutomationInfra
          </StyledNavLink>

          <StyledButton
            aria-describedby={id}
            onClick={(event: any) => {
              setAnchorEl(event.currentTarget);
              setIsOpen(true);
            }}
            disableRipple={true}
            {...{ theme }}
          >
            Content <ArrowDropDownIcon />
          </StyledButton>
          <ContentMenu
            isOpen={isOpen}
            anchorElProp={anchorEl}
            handleClose={handleClose}
          />
          <StyledNavLink
            to="/reviews"
            {...{ theme }}
            onClick={() => Mixpanel.track("Header navigation reviews.")}
          >
            Reviews
          </StyledNavLink>
          <StyledNavLink
            to="/team"
            {...{ theme }}
            onClick={() => Mixpanel.track("Header navigation team page.")}
          >
            Team
          </StyledNavLink>

          <StyledNavLink
            to="/products-price"
            {...{ theme }}
            onClick={() => Mixpanel.track("Header navigation purchase.")}
          >
            <b>Purchase</b>
          </StyledNavLink>
        </LinksContainer>
      ) : (
        ""
      )}

      {isSmallerDevice ? (
        <div>
          <ThemeAndLoginBtnContainer>
            {/*<Tooltip title="Switch theme" arrow>*/}
            {/*  <ThemeButton {...{ theme }} onClick={toggleTheme}>*/}
            {/*    {currentTheme ? (*/}
            {/*      <EmojiObjectsIcon />*/}
            {/*    ) : (*/}
            {/*      <EmojiObjectsOutlinedIcon />*/}
            {/*    )}*/}
            {/*  </ThemeButton>*/}
            {/*</Tooltip>*/}
            {/*<UserContainer {...{ theme }} onClick={() => signOut()}>*/}
            <UserMenu setIsShowLogin={setIsShowLogin} />
            {/*</UserContainer>*/}
          </ThemeAndLoginBtnContainer>
          <LoginPopup
            isShowLogin={isShowLogin}
            setIsShowLogin={setIsShowLogin}
          />
        </div>
      ) : (
        <Drawer />
      )}
    </StyledHeader>
  );
};

const StyledHeader = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f6f9fc;
  padding: 1rem 9rem;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 990;
  border-radius: 10px;

  @media (max-width: 820px) {
    padding: 1rem;
  }
`;

const LogoContainer = styled(NavLink)`
  display: flex;
  align-items: center;
`;

const LinksContainer = styled.div`
  width: 40rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-items: baseline;
`;

const StyledNavLink = styled(NavLink)`
  color: #000000;
  font-size: 1.2rem;

  &:hover,
  &:focus,
  &:active {
    color: #747474;
    opacity: 1;
    text-decoration: none;
    outline: none;
  }

  &.active {
    opacity: 1;
    border-bottom: 1px solid ${(props: any) => props.theme.palette.primary.main};
  }

  &:after {
    transition: all ease-in-out 0.2s;
    background: ${(props: any) => props.theme.palette.primary.main};
    content: "";
    display: block;
    height: 2px;
    width: 0;
  }

  &:hover:after {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  background: none !important;
  color: #000000 !important;
  display: flex;
  align-items: center;
  margin-bottom: 1px !important;
  font-size: 1.2rem !important;
  text-transform: none;

  span {
    font-size: 1.2rem;
    text-transform: none;
  }

  &:hover {
    opacity: 1;
  }
`;

const ThemeButton = styled(Button)`
  margin-right: 3rem !important;
`;

const ThemeButtonInMenu = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const ThemeAndLoginBtnContainer = styled.div`
  display: flex;
`;
